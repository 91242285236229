table {
    border-collapse: separate !important;
    border-spacing: 0px 8px;
}

th {
    position: relative;
    font-family: "Neue Haas Unica";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    max-height: 32px;
    padding: 8px 8px 8px 16px;
    background: #f5f5f5;
    border-radius: 4px;
    border-right: 16px solid #fff;
    border-bottom: 8px solid #fff;
}

th:last-child {
    border-right: none;
}

td {
    font-family: "Neue Haas Unica";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding: 16px;
    background: #f5f5f5;
}

td:nth-child(4n),
td:last-child {
    text-align: right;
}

.tbody_item td {
    cursor: pointer;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
}

.tbody_item:hover td {
    background: rgba(0, 0, 0, 0.1) !important;
}

.status-1 {
    color: #3fa4dc;
}

.status-2 {
    color: #558f4f;
}

.type-cell {
    min-height: 65px;
}

.mobile-table-header {
    display: none;
}

@media screen and (max-width: 600px) {
    .mobile-table-header {
        display: block;
        color: #111;
    }

    .type-cell div {
        display: flex;
        justify-content: flex-end !important;
        min-height: auto;
    }

    table {
        border: 0;
    }

    table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    table tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
    }

    table td {
        border-bottom: 1px solid #ddd;
        display: flex;
        justify-content: space-between;
        font-size: .8em;
        text-align: right;
        min-height: auto !important;
    }

    table td p {
        min-width: 20% !important;
    }

    table td p span span {
        margin-left: -4px !important;
    }

    table td:last-child {
        border-bottom: 0;
    }

    .table-tooltip_icon {
        display: none;
    }
}