.phone_input .css-1s2u09g-control, .phone_input .css-1pahdxg-control {
    min-width: 135px !important;

    height: 100% !important;
    margin-right: 16px;
}

.css-1pahdxg-control:hover {
    border-color: hsl(0, 0%, 80%) !important;
}

.css-1pahdxg-control:hover, .css-1pahdxg-control:focus {
    border-color: hsl(0, 0%, 80%) !important;

    outline: none !important;
    box-shadow: none !important;
}

.css-1pahdxg-control {
    border-color: hsl(0, 0%, 80%) !important;
    outline: none !important;
    box-shadow: none !important;

}

/* .css-2613qy-menu */
#react-select-2-listbox>*, #react-select-3-listbox>* {
    max-height: 120px !important;
}

#react-select-4-listbox {
    max-width: 135px;
    min-width: 135px;
    width: 100%;
}