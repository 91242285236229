.input-gray-border {
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    border-radius: 4px;
}

.input-red-border {
    background: #FFFFFF;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 4px;
}

.input-blue-border {
    background: #FFFFFF;
    border: 1px solid #1356DC;
    box-sizing: border-box;
    border-radius: 4px;
}