@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: NeueHaasUnica-Bold;
  src: url("./assets/fonts/NeueHaasUnica-Bold.ttf") format("opentype");
}

@font-face {
  font-family: NeueHaasUnica-ExtraLight;
  src: url("./assets/fonts/NeueHaasUnica-ExtraLight.ttf") format("opentype");
}

@font-face {
  font-family: NeueHaasUnica;
  src: url("./assets/fonts/NeueHaasUnica-Regular.ttf") format("opentype");
}

body {
  background-color: #F5F5F5;
}

#root {
  overflow: hidden;
}

/* max width of the app 1440px */
body>* {
  max-width: 1440px;
  width: 100%;
  margin: auto;
}

/* NOTE: */
* {
  -ms-overflow-style: none;
  /* IE 11 */
  scrollbar-width: none;
  /* Firefox 64 */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

iframe {
  display: none !important;
}

.swal2-container {
  max-width: 100vw;
}

/* spinner */
.loader {
  position: relative;
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  min-height: 24px !important;
  left: 47%;
}





.loader:before {
  position: absolute;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  border: 1.5px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
}

.loader:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: 1.5px solid #fff;
  border-right: 1.5px solid #fff;
  border-radius: 50%;
  -webkit-animation: spinner .5s linear infinite;
  animation: spinner .5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .loader {
    left: 130%;
  }
}