 .language-switcher {
     position: relative;
     display: flex;
     align-items: center;
     border: 1px solid #e8e8e8;
     box-sizing: border-box;
     border-radius: 32px;
     padding: 8px;
 }

 .language-switcher input {
     border: none;
     outline: none;
     background: transparent;

     width: 100%;
 }

 .rotate {
     transform: rotate(-180deg);
 }

 .dropdown_icon {
     display: block;
     pointer-events: none;

     border-right: solid 3px transparent;
     border-left: solid 3px transparent;
     border-top: solid 5px #000;
     -webkit-transition: all 0.15s ease-in-out;
     transition: all 0.15s ease-in-out;
     width: 0px;
     height: 0px;
 }

 .hide-dropdown {
     transform: scaleY(0);
     transition: all 0.3s ease;
 }

 .show-dropdown {
     transform: scaleY(1);
     transition: all 0.3s ease;
 }

 .language-switcher_container ul {
     position: absolute;
     transform-origin: top;
     padding: 0px 30px;
 }

 .language-switcher_container ul li {
     list-style-type: none;
     cursor: pointer;
 }