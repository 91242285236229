[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="checkbox"]:checked+label,
[type="checkbox"]:not(:checked)+label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}

[type="checkbox"]:checked+label:before,
[type="checkbox"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    box-sizing: border-box;
    border-radius: 2px;
}

[type="checkbox"]:checked+.checkbox-error:before,
[type="checkbox"]:not(:checked)+.checkbox-error:before {
    border: 1px solid #FA3838;
}

[type="checkbox"]:checked+label:after,
[type="checkbox"]:not(:checked)+label:after {
    content: '';
    width: 5px;
    height: 8px;
    background: transparent;
    position: absolute;
    top: 3px;
    left: 6px;
    border-bottom: 2px solid #000;
    border-right: 2px solid #000;
    transform: rotate(45deg);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="checkbox"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="checkbox"]:checked+label:after {
    opacity: 1;
}

.css-1insrsq-control {
    background: #fff !important;
}