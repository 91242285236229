 .timer {
     position: relative;
 }

 .timer_display {
     position: absolute;
     top: 0;
     left: 0;
     bottom: 0;
     right: 0;
     margin: 0;
     width: 100%;
 }

 .spinner {
     overflow: hidden;
     position: relative;
     width: 32px;
     height: 32px;
     background: white;
 }

 .spinner:before {
     position: absolute;
     width: inherit;
     height: inherit;
     border-radius: 50%;
     background: white;
     box-shadow: inset 0 0 0 2px #144FC5;
     content: '';
 }

 .spinner .cover {
     position: absolute;
     margin: 0 -50%;
     width: 200%;
     height: inherit;
     transform-origin: 50% 0;
     background: white;
     animation: revealCircle 20s linear infinite forwards;
     -webkit-animation: revealCircle 20s linear infinite forwards;
 }

 @keyframes revealCircle {
     to {
         transform: rotate(180deg);
     }
 }